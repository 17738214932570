import {IEnvironment} from "./ienvironment";

export const environment: IEnvironment = {
  production: false,
  releaseVersion: '1.1',
  environmentName: 'Staging',
  // demeterApi: 'https://localhost:5001',
  demeterApi: 'https://staging-demeter.planted-labs.com',
  // rootApp: 'http://localhost:4201',
  key: 'plttk',
  urlRegex: /https:\/\/(\w+)?-?(\w+).planted-labs.com|https:\/\/localhost:[0-9]?[0-9]?[0-9]?[0-9]?/,
  crashPAD: 'https:localhost:4200',
  erp: 'https://planteddetroit.erpnext.com/login'
};
