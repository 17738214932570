import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../models/user';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private app = 'Root';

  constructor(private cookieService: CookieService) {
  }

  Get(): User {
    if (environment.production) {
      console.log(this.app + ' PROD: Getting Token');
      return JSON.parse(localStorage.getItem(environment.key));
    } else {
      console.log(this.app + ' DEV: Getting Token');
      return JSON.parse(this.cookieService.get(environment.key));
    }
  }

  Set(user: User): void {
    if (environment.production) {
      console.log(this.app + ' PROD: Setting Token');
      localStorage.setItem(environment.key, JSON.stringify(user));
    } else {
      console.log(this.app + ' DEV: Setting Token');
      this.cookieService.set(environment.key, JSON.stringify(user));
    }
  }

  Delete(): void {
    if (environment.production) {
      console.log(this.app + ' PROD: Deleting Token');
      localStorage.removeItem(environment.key);
    } else {
      console.log(this.app + ' DEV: Deleting Token');
      this.cookieService.delete(environment.key);
    }
  }

  Check(): boolean {
    if (environment.production) {
      console.log(this.app + ' PROD: Checking Token');
      if (localStorage.getItem(environment.key)) {
        return true;
      } else {
        return false;
      }
    } else {
      console.log(this.app + ' DEV: Checking Token');
      return this.cookieService.check(environment.key);
    }
  }
}
