<div class="root-landing-wrapper">
    <img class="title" src="assets/fullcolor_wordmark_1000px.png">
  <div id="Actions">
    <span>Actions</span>
    <p-button *ngFor="let action of actions"
      (onClick)="action.clickFunction()" label="{{action.name}}" styleClass="p-button-lg" [disabled]="action.disabled"></p-button>
  </div>
  <div id="Apps">
    <span>Applications</span>
    <p-button *ngFor="let app of apps"
              (onClick)="app.clickFunction()" label="{{app.name}}" styleClass="p-button-lg" [disabled]="app.disabled"></p-button>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>
