import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TokenService} from '../../services/token-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private http: HttpClient,
    private tokenService: TokenService) {
  }

  ngOnInit(): void {

    console.log(`${environment.production ? 'Prod' : 'Non Prod'}`);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    let termUrl = new URLSearchParams(window.location.search).get('term') || '/';
    // if (new RegExp(environment.urlRegex).test(termUrl)) {
    console.log({termUrl})
      this.returnUrl = termUrl;
    // } else {
    //   this.returnUrl = null;
    // }
  }

  // convenience getter for easy access to form fields
  get f(): { [p: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  async onSubmit(): Promise<void> {
    console.log('Submitting');
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // console.log(this.returnUrl);
    this.loading = true;
    let username = this.f.username.value;
    let password = this.f.password.value;
    // console.log({username, password});
    // @ts-ignore
    this.http.post<string>(environment.demeterApi + '/planted/auth/login', {username, password}, {responseType: 'text'}).subscribe(
      async resp => {
        // @ts-ignore
        let holdover = JSON.parse(resp);
        this.alertService.success('Success!');
        console.log('Demo');
        await this.delay(500);
        console.log({returnUrl: this.returnUrl});
        window.location.href = this.returnUrl + '?holdOver=' + holdover.id;
      },
      error => {
        // console.log('Error');
        this.alertService.error('Your Username or Password is incorrect');
        this.tokenService.Delete();
        this.loading = false;
      });

    // console.log('Exiting');
  }

  delay(ms: number): Promise<void> {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async Register(): Promise<void> {
    // this.alertService.error('Contact Your System Administrator to Register');
    console.log('Entering');
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    console.log(this.returnUrl);
    this.loading = true;
    let username = this.f.username.value;
    let password = this.f.password.value;
    console.log({username, password});
    // @ts-ignore
    await this.http.post<string>(environment.demeterApi + '/planted/auth/register', {username, password}, { responseType: 'text'}).subscribe(
      () => {
        this.alertService.success('User Registered. Contact your System Administrator to activate your account.')
      },
      error => {
        console.log('Error');
        this.alertService.error('Failure. Please contact your System Administrator.');
      });

    console.log('Exiting');
  }
}
