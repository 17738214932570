import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {QuickLinksComponent} from './components/quick-links/quick-links.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: '' }
];

export const AppRouterModule = RouterModule.forRoot(appRoutes);
