<div id="loginForm">
  <img id="logo" src="https://planted-assets.s3.us-east-2.amazonaws.com/fullcolor_leaf_400px.png">
  <form id="theForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input id="username" type="text"
             pInputText formControlName="username"
             placeholder="Username"
             class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
        <div *ngIf="f.username.errors.required">Username is required</div>
      </div>
    </div>
    <br/>
    <div id="password" class="form-group">
      <input pInputText
             type="password" formControlName="password"
             placeholder="Password"
             class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Password is required</div>
      </div>
    </div>
    <br>
    <div class="button-row">
      <div class="form-group">
        <button id="login" pButton pRipple type="submit" label="Login" class="p-button-raised p-button-success"></button>
      </div>
      <div class="form-group">
        <button id="register" pButton pRipple type="button" label="Register" class="p-button-raised p-button-info"
        (click)="Register()"></button>
      </div>
    </div>
  </form>
</div>
<p-toast position="bottom-right"></p-toast>
