import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.css']
})
export class QuickLinksComponent implements OnInit {

  updateHarvestsDisabled: boolean;

  actions: any[] = [
    {
      name: 'Update Harvests', extension: '/artemis/update-harvests',
      clickFunction: () => this.actionClick(this.actions[0]), disabled: false,
      successMessage: 'Successfully Updated Harvests', errorMessage: 'Failed to Update Harvests'
    },
    {
      name: 'Update Crops', extension: '/artemis/update-crops',
      clickFunction: () => this.actionClick(this.actions[1]), disabled: false,
      successMessage: 'Successfully Updated Crops', errorMessage: 'Failed to Update Crops'
    }
  ];

  apps: any[] = [
    {
      name: 'CrashPAD', url: environment.crashPAD, internal: true,
      clickFunction: () => this.appClick(this.apps[0]), disabled: false
    },
    {
      name: 'ERP Next', url: environment.erp, internal: false,
      clickFunction: () => this.appClick(this.apps[1]), disabled: false
    }
  ];


  constructor(private http: HttpClient, private alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  async actionClick(button: any): Promise<void> {
    button.disabled = true;
    await this.http.get(environment.demeterApi + button.extension,
      {headers: {responseType: 'text'}})
      .toPromise().then(resp => {
        this.alertService.success(button.successMessage);
      }).catch(err => {
        console.log(err);
        this.alertService.error(button.errorMessage);
      });

    button.disabled = false;
  }

  async appClick(button: any): Promise<void> {
    button.disabled = true;
    this.alertService.success('Moving to ' + button.name);
    if (button.internalApp) {
      // Holdover Token Logic
    }

    window.location.href = button.url;
    button.disabled = false;
  }

  ToCrashPAD(): Promise<void> {
    return null;
  }

  private UpdateCrops(): Promise<void> {
    return Promise.resolve(undefined);
  }
}
