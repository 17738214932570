import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private toastService: MessageService) {
  }

  success(message: string, keepAfterNavigationChange = false): void {
    this.toastService.add({ severity: 'success', detail: message, });
  }

  error(message: string, keepAfterNavigationChange = false): void {
    this.toastService.add({ severity: 'error', detail: message});
  }
}
